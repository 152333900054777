import * as Sentry from "@sentry/nextjs";
import { Spinner } from "flowbite-react";
import momentTz from "moment-timezone";
import Head from "next/head";
import { useRouter } from "next/router";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import routes from "shared/dist/constants/routes";
import * as AuthServices from "shared/dist/services/AuthServices";
import * as CustomerServices from "shared/dist/services/CustomerService";
import * as TenantServices from "shared/dist/services/TenantServices";
import { ILoggedInCustomerDetails } from "shared/dist/types/CustomerTypes";
import {
  AuthUserResponseType,
  UserPermissionSectionsEnum,
  UserPermissionsEnum,
} from "shared/dist/types/UserTypes";
import { IDomain } from "shared/dist/types/UserTypes";
import { getBaseUrl } from "shared/dist/utils/Common";
import { getCookie, removeCookie, setCookie } from "shared/dist/utils/CookieHelper";
import {
  getSelectedDomain,
  getSelectedTenant,
  removeSelectedDomain,
  removeSelectedTenant,
  storeXDomain,
  storeXDomainCurrency,
  storeXDomainTimezone,
  storeXTenant,
} from "shared/dist/utils/TenantHelper";
import { CustomFeatureForRole } from "shared/src/types/StaffManagementServicesType";
import { storeXPlatform } from "shared/src/utils/TenantHelper";
import useSWR from "swr";

export type AuthContextType = {
  user: AuthUserResponseType;
  setUser: Dispatch<SetStateAction<AuthUserResponseType | undefined>>;
  isLoading: boolean;
  isAuthenticated: boolean;
  impersonating: boolean;
  setImpersonating: Dispatch<SetStateAction<boolean>>;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  token: string | null | undefined;
  setToken: Dispatch<SetStateAction<string | null | undefined>>;
  selectedTenant: string | null | undefined;
  setSelectedTenant: Dispatch<SetStateAction<string | null | undefined>>;
  selectedDomain: string | null | undefined;
  setSelectedDomain: Dispatch<SetStateAction<string | null | undefined>>;
  selectedDomainTimeZone: string | null | undefined;
  setSelectedDomainTimeZone: Dispatch<SetStateAction<string | null | undefined>>;
  selectedDomainCurrency: string | null | undefined;
  setSelectedDomainCurrency: Dispatch<SetStateAction<string | null | undefined>>;
  selectedDomainName: string;
  setSelectedDomainName: Dispatch<SetStateAction<string | null | undefined>>;
  checkAuth: () => Promise<void>;
  doLogout: () => Promise<void>;
  getAuthenticatedUsers: () => Promise<AuthUserResponseType>;
  getAuthenticatedCustomer: () => Promise<ILoggedInCustomerDetails>;
  resetData: () => Promise<void>;
  domainDetails: IDomain;
  refetchDomainDetails: () => Promise<void>;
  canView: (section: UserPermissionSectionsEnum) => boolean;
  canCreate: (section: UserPermissionSectionsEnum) => boolean;
  canEdit: (section: UserPermissionSectionsEnum) => boolean;
  canDelete: (section: UserPermissionSectionsEnum) => boolean;
  canSeePerParticipantWaiver: () => boolean;
  canSeePerParticipantDocuments: () => boolean;
  canSeePerBookingWaiver: () => boolean;
  canSeePerBookingDocuments: () => boolean;
  canCancelPastBookingsOnOrderCancellation: () => boolean;
  isFeatureEnabledForUserRole: (feature: CustomFeatureForRole) => boolean;
  isPaymentIntegrationEnabledForUserRole: (integrationId: string) => boolean;
  publicDomainDetails: IDomain;
  fetchPublicDomainDetails: () => Promise<void>;
  redirectToLogin: () => void;
  isCustomerLoggedIn: boolean;
  setIsCustomerLoggedIn: Dispatch<SetStateAction<boolean>>;
  loggedInCustomerDetails: ILoggedInCustomerDetails;
  setLoggedInCustomerDetails: Dispatch<SetStateAction<ILoggedInCustomerDetails>>;
};

export const AuthContext = createContext<AuthContextType>(undefined);

export const PROTECTED_ROUTES = [
  routes.registerBusiness,
  routes.selectDomain,
  routes.dashboard,
  routes.orders.root,
  routes.orders.pendingOrders,
  routes.orders.cancelledOrders,
  routes.bookings.root,
  routes.bookings.root,
  routes.reports.root,
  routes.staffManagement.root,
  routes.communications.root,
  routes.settings.root,
  routes.promoCode.root,
  routes.voucher.root,
  routes.assets.root,
  routes.pricingSeason.root,
  routes.merchandise.root,
  routes.product.root,
  routes.locations.add,
  routes.widgets.root,
  routes.affiliate.root,
  routes.customers,
  routes.calendar.root,
  routes.manifest,
  routes.waivers.root,
];
export const CUSTOMER_PROTECTED_ROUTES = [
  routes.customer.orders.root,
  routes.customer.bookings.root,
  routes.customer.memberships.root,
  routes.customer.memberships.viewMembership,
  routes.customer.settings,
  routes.customer.contactDetails,
];

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const router = useRouter();
  const [user, setUser] = useState<AuthUserResponseType>();
  const [token, setToken] = useState<string | null>(null);
  const [selectedTenant, setSelectedTenant] = useState<string | null>();
  const [selectedDomain, setSelectedDomain] = useState<string | null>();
  const [selectedDomainTimeZone, setSelectedDomainTimeZone] = useState<string | null>();
  const [selectedDomainCurrency, setSelectedDomainCurrency] = useState<string | null>();
  const [impersonating, setImpersonating] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [domainDetails, setDomainDetails] = useState<IDomain>([]);
  const [selectedDomainName, setSelectedDomainName] = useState<string>();
  // Customer-login parameters
  const [isCustomerLoggedIn, setIsCustomerLoggedIn] = useState(
    CUSTOMER_PROTECTED_ROUTES.includes(router.pathname) ?? false,
  );
  const [loggedInCustomerDetails, setLoggedInCustomerDetails] =
    useState<ILoggedInCustomerDetails>();

  const redirectToLogin = () => {
    let isRouteProtected = false;
    if (isCustomerLoggedIn) {
      CUSTOMER_PROTECTED_ROUTES.forEach((route) => {
        if (router.pathname === route) {
          isRouteProtected = true;
        }
      });
      isRouteProtected &&
        window.location.assign(
          selectedDomainName ?? (publicDomainDetails as IDomain).domain
            ? `${window.location.protocol}//${selectedDomainName ?? (publicDomainDetails as IDomain).domain}.${getBaseUrl()}/customer/login`
            : `${location.origin}/customer/login`,
        );
    } else {
      PROTECTED_ROUTES.forEach((route) => {
        if (router.pathname.includes(route)) {
          isRouteProtected = true;
        }
      });
      isRouteProtected &&
        window.location.assign(`${window.location.protocol}//${getBaseUrl()}/login`);
    }
  };

  const canView = useCallback(
    (section: UserPermissionSectionsEnum) =>
      typeof user?.permissions[section] !== "undefined"
        ? user.permissions[section] !== UserPermissionsEnum.notAllowed
        : true,
    [user?.permissions],
  );

  const canCreate = useCallback(
    (section: UserPermissionSectionsEnum) =>
      typeof user?.permissions[section] !== "undefined"
        ? user?.permissions[section] === UserPermissionsEnum.contributor ||
          user.permissions[section] === UserPermissionsEnum.manager
        : true,
    [user?.permissions],
  );

  const canEdit = useCallback(
    (section: UserPermissionSectionsEnum) =>
      typeof user?.permissions[section] !== "undefined"
        ? user?.permissions[section] === UserPermissionsEnum.contributor ||
          user.permissions[section] === UserPermissionsEnum.manager
        : true,
    [user?.permissions],
  );

  const canDelete = useCallback(
    (section: UserPermissionSectionsEnum) =>
      typeof user?.permissions[section] !== "undefined"
        ? user.permissions[section] === UserPermissionsEnum.manager
        : true,
    [user?.permissions],
  );

  const canSeePerParticipantWaiver = useCallback(
    () => (!Array.isArray(domainDetails) ? domainDetails?.enable_per_participant_waiver : true),
    [domainDetails],
  );

  const canSeePerParticipantDocuments = useCallback(
    () => (!Array.isArray(domainDetails) ? domainDetails?.enable_per_participant_documents : true),
    [domainDetails],
  );

  const canSeePerBookingWaiver = useCallback(
    () => (!Array.isArray(domainDetails) ? domainDetails?.enable_per_booking_waiver : true),
    [domainDetails],
  );

  const canSeePerBookingDocuments = useCallback(
    () => (!Array.isArray(domainDetails) ? domainDetails?.enable_per_booking_documents : true),
    [domainDetails],
  );

  const canCancelPastBookingsOnOrderCancellation = useCallback(
    () =>
      !Array.isArray(domainDetails)
        ? domainDetails?.cancel_past_bookings_on_order_cancellation
        : true,
    [domainDetails],
  );

  const isFeatureEnabledForUserRole = useCallback(
    (feature: CustomFeatureForRole) =>
      user?.roles?.length && user.roles[0]?.custom?.length
        ? Boolean(user.roles[0].custom.find((cf) => cf === feature))
        : false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.roles],
  );

  const isPaymentIntegrationEnabledForUserRole = useCallback(
    (integrationId: string) => {
      return user?.roles?.length && user.roles[0]?.integrations?.length
        ? Boolean(
            user.roles[0].integrations?.find((integration) => integration.id === integrationId),
          )
        : false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.roles],
  );

  const restrictedRoutes = useMemo(() => {
    if (!user?.permissions) {
      return [];
    }

    return Object.keys(user.permissions).filter(
      (permission) =>
        user.permissions[permission] === UserPermissionsEnum.notAllowed &&
        permission !== "dashboard",
    );
  }, [user?.permissions]);

  // GET BASIC DETAIL OS USERS AND REDIRECT TO LOGIN PAGE IF GET 401 FROM RESPONSE
  const getAuthenticatedUsers = async () => {
    try {
      const { data } = await AuthServices.getAuthUser();
      setUser(data);
      if (
        // TODO: Need to update backend to use the key "staff-management" instead of "user"
        restrictedRoutes.some((v) =>
          v === "user" ? router.pathname.includes("staff-management") : router.pathname.includes(v),
        )
      ) {
        router.replace("/500");
      }
      return data;
    } catch (e: any) {
      Sentry.captureException(e);
      redirectToLogin();
    }
  };
  const getAuthenticatedCustomer = async () => {
    try {
      const { data } = await CustomerServices.getAuthCustomer();
      setIsCustomerLoggedIn(true);
      setLoggedInCustomerDetails(data);
      return data;
    } catch (e: any) {
      Sentry.captureException(e);
      redirectToLogin();
    }
  };

  // CHECK LOCALSTORAGE AND SET DATA AFTER VALIDATING THE TOKEN
  const { isLoading: isLoading = true, mutate: checkAuth } = useSWR(
    "checkAuth",
    async () => {
      try {
        const token = getCookie({ key: "token" });
        const selectedTenant = getSelectedTenant();
        const selectedDomain = getSelectedDomain();
        setToken(token);
        setSelectedTenant(selectedTenant);
        setSelectedDomain(selectedDomain);
        if (CUSTOMER_PROTECTED_ROUTES.includes(router.pathname)) {
          setIsCustomerLoggedIn(true);
          if (token && selectedTenant && selectedDomain) {
            await getAuthenticatedCustomer();
          } else {
            redirectToLogin();
          }
        } else if (
          router.pathname.indexOf(routes.widgets.integratedWidget) <= -1 &&
          router.pathname.indexOf(routes.widgets.affiliate_widget) <= -1 &&
          !router.pathname.includes(routes.webview) &&
          router.pathname !== routes.payOrder.root &&
          // Don't Authenticate users in the customer login flow
          !CUSTOMER_PROTECTED_ROUTES.includes(router.pathname)
        ) {
          await getAuthenticatedUsers();
        }
        if (token) {
          setIsAuthenticated(true);
        }
      } catch (e: any) {
        Sentry.captureException(e);
        setIsAuthenticated(false);
        redirectToLogin();
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const resetData = async () => {
    removeSelectedTenant();
    removeSelectedDomain();
    removeCookie({ key: "token" });
    setToken(null);
    setSelectedTenant(null);
    setSelectedDomain(null);
    setIsAuthenticated(false);
    setLoggedInCustomerDetails(undefined);
    setIsCustomerLoggedIn(false);
  };

  const doLogout = async () => {
    try {
      redirectToLogin();
      await resetData();
    } catch (e: any) {
      Sentry.captureException(e);
      console.error({ e });
    }
  };

  const {
    data: publicDomainDetails = {},
    isLoading: isDomainDetailsLoading = true,
    mutate: fetchPublicDomainDetails,
    error: domainDetailsError,
  } = useSWR<IDomain>(
    "domainPublicDetails",
    async () => {
      try {
        const { data } = await TenantServices.getDomainBasicDetails();
        return data;
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  useEffect(() => {
    if (domainDetailsError) redirectToLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainDetailsError]);

  // domian value check
  useEffect(() => {
    const domainCheck = async () => {
      try {
        const { data } = await TenantServices.getDomainDetails();
        setDomainDetails(data);
        if (isAuthenticated && data.timezone_iso3 === null) {
          return router.push(routes.registerBusiness);
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error("Domain call failed!", e);
        redirectToLogin();
      }
    };
    if (
      isAuthenticated &&
      router.pathname.indexOf(routes.widgets.integratedWidget) <= -1 &&
      router.pathname.indexOf(routes.widgets.affiliate_widget) <= -1 &&
      router.pathname !== routes.payOrder.root &&
      // Don't Authenticate users in the customer login flow
      !CUSTOMER_PROTECTED_ROUTES.includes(router.pathname)
    ) {
      domainCheck();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const refetchDomainDetails = async () => {
    if (!CUSTOMER_PROTECTED_ROUTES.includes(router.pathname)) {
      try {
        const { data } = await TenantServices.getDomainDetails();
        setDomainDetails(data);
      } catch (e) {
        Sentry.captureException(e);
        console.error("Domain call failed!", e);
        redirectToLogin();
      }
    }
  };

  useEffect(() => {
    if (
      restrictedRoutes.some((v) =>
        // TODO: Need to update backend to use the key "staff-management" instead of "user"
        v === "user" ? router.pathname.includes("staff-management") : router.pathname.includes(v),
      )
    ) {
      router.replace("/500");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictedRoutes, router.pathname]);
  // REDIRECT USER TO SELECT DOMAIN SCREEN AS TENANT IS NOT SELECTED
  // useEffect(() => {
  //   if (isAuthenticated && !selectedTenant && router.isReady && router.pathname.indexOf(routes.selectDomain) > -1) {
  //     router.push(routes.selectDomain)
  //   }
  // }, [isAuthenticated, selectedTenant, router])
  useEffect(() => {
    if (CUSTOMER_PROTECTED_ROUTES.includes(router.pathname)) {
      setIsCustomerLoggedIn(true);
    }
    if (
      (isCustomerLoggedIn || CUSTOMER_PROTECTED_ROUTES.includes(router.pathname)) &&
      typeof loggedInCustomerDetails === "undefined"
    ) {
      getAuthenticatedCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, isCustomerLoggedIn]);

  useEffect(() => {
    if (token) {
      setCookie({ key: "token", value: token });
    } else {
      removeCookie({ key: "token", params: { path: "" } });
    }
  }, [token]);

  useEffect(() => {
    if (selectedTenant) {
      storeXTenant(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedDomain) {
      storeXDomain(selectedDomain);
    }
  }, [selectedDomain]);

  useEffect(() => {
    if (selectedDomainTimeZone) {
      momentTz.tz.setDefault(selectedDomainTimeZone);
      storeXDomainTimezone(selectedDomainTimeZone);
    }
  }, [selectedDomainTimeZone]);

  useEffect(() => {
    if (selectedDomainCurrency) {
      storeXDomainCurrency(selectedDomainCurrency);
    }
  }, [selectedDomainCurrency]);

  useEffect(() => {
    storeXPlatform();
  }, []);

  const value = {
    user,
    setUser,
    isLoading,
    isAuthenticated,
    setIsAuthenticated,
    token,
    impersonating,
    setImpersonating,
    selectedTenant,
    setSelectedTenant,
    selectedDomain,
    setSelectedDomain,
    selectedDomainName,
    setSelectedDomainName,
    selectedDomainTimeZone,
    setSelectedDomainTimeZone,
    selectedDomainCurrency,
    setSelectedDomainCurrency,
    setToken,
    checkAuth,
    doLogout,
    getAuthenticatedUsers,
    getAuthenticatedCustomer,
    resetData,
    domainDetails,
    refetchDomainDetails,
    canView,
    canCreate,
    canEdit,
    canDelete,
    canSeePerParticipantWaiver,
    canSeePerParticipantDocuments,
    canSeePerBookingDocuments,
    canCancelPastBookingsOnOrderCancellation,
    canSeePerBookingWaiver,
    isFeatureEnabledForUserRole,
    isPaymentIntegrationEnabledForUserRole,
    publicDomainDetails,
    fetchPublicDomainDetails,
    redirectToLogin,
    isCustomerLoggedIn,
    setIsCustomerLoggedIn,
    loggedInCustomerDetails,
    setLoggedInCustomerDetails,
  };
  return (
    <AuthContext.Provider value={value}>
      {isLoading ? (
        <div className="mt-10 text-center">
          <Spinner aria-label="Center-aligned spinner example" />
        </div>
      ) : (
        <>
          <Head>
            <script
              id="domaindetails"
              data-json={JSON.stringify(publicDomainDetails)}
              dangerouslySetInnerHTML={{
                __html: `window.myJsonData = document.getElementById('domaindetails').getAttribute('data-json');`,
              }}
            />
            <script src="//ajax.googleapis.com/ajax/libs/jquery/1.8.2/jquery.min.js"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  function start() {
                    gapi.load('auth2', function() {
                      auth2 = gapi.auth2.init({
                        client_id: "275324822212-6ob00vh3ouo02om57onfgp1fb5ve4181.apps.googleusercontent.com",
                      });
                    });
                  }
                `,
              }}
            />
          </Head>
          {children}
        </>
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
