import routes from "./routes";
// Any hard coded filter or sort options

export const ProductAvailabilityTimeSlotWeekDays = [
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
  { label: "Sun", value: 0 },
];

export const Increments = [
  {
    label: "30 mins",
    value: "0.5",
  },
  {
    label: "1 hour",
    value: "1",
  },
];

export const timeSlot = [
  {
    label: "Minute",
    value: "minute",
  },
  {
    label: "Hour",
    value: "hour",
  },
];

export const CutOffOptions = [
  {
    label: "Minute",
    value: "minute",
  },
  {
    label: "Hour",
    value: "hour",
  },
  {
    label: "Day",
    value: "day",
  },
];

export const CustomFields = [
  { label: "Text Field", value: "text" },
  { label: "Dropdown", value: "list" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Radio", value: "radio" },
  { label: "Multi-line text", value: "multi" },
  { label: "Inline text (read-only)", value: "inline" },
  { label: "Agreement", value: "agreement" },
  { label: "Rich Text", value: "rich_text" },
  { label: "Date Field", value: "date" },
  // { label: "File Upload", value: "file" },
];

export const MultiOptionsCustomFields = ["list", "radio", "checkbox", "agreement"];

export const DYNAMIC_INVENTORY = "dynamic";
export const FIXED_INVENTORY = "fixed";
export const UNLIMITED_INVENTORY = "unlimited";
export const ProductSteps = [
  {
    label: "Type",
    value: routes.product.type.split("/").pop(),
  },
  {
    label: "Info",
    value: routes.product.info.split("/").pop(),
  },
  {
    label: "Location",
    value: routes.product.location.split("/").pop(),
  },
  {
    label: "Inventory",
    value: routes.product.inventory.split("/").pop(),
  },
  {
    label: "Scheduling",
    value: routes.product.scheduling.split("/").pop(),
  },
  {
    label: "Ticket Type",
    value: routes.product.ticket.split("/").pop(),
  },
  {
    label: "Price Sheet",
    value: routes.product.price.split("/").pop(),
  },
  {
    label: "Booking Info",
    value: routes.product.booking.split("/").pop(),
  },
  {
    label: "Extras",
    value: routes.product.extra.split("/").pop(),
  },
  {
    label: "Summary",
    value: routes.product.summary.split("/").pop(),
  },
];

export const ProductRentalSteps = [
  {
    label: "Type",
    value: routes.product.type.split("/").pop(),
  },
  {
    label: "Info",
    value: routes.product.info.split("/").pop(),
  },
  {
    label: "Location",
    value: routes.product.location.split("/").pop(),
  },
  {
    label: "Inventory",
    value: routes.product.inventory.split("/").pop(),
  },
  {
    label: "Ticket Type",
    value: routes.product.ticket.split("/").pop(),
  },
  {
    label: "Scheduling",
    value: routes.product.scheduling.split("/").pop(),
  },
  {
    label: "Price Sheet",
    value: routes.product.price.split("/").pop(),
  },
  {
    label: "Booking Info",
    value: routes.product.booking.split("/").pop(),
  },
  {
    label: "Extras",
    value: routes.product.extra.split("/").pop(),
  },
  {
    label: "Summary",
    value: routes.product.summary.split("/").pop(),
  },
];

export const ProductGiftCardSteps = [
  {
    label: "Type",
    value: routes.product.type.split("/").pop(),
  },
  {
    label: "Info",
    value: routes.product.info.split("/").pop(),
  },
  {
    label: "Value & Expiry",
    value: routes.product["value-and-expiry"].split("/").pop(),
  },
  {
    label: "Extra",
    value: routes.product["gift-extra"].split("/").pop(),
  },
  {
    label: "Summary",
    value: routes.product["gift-summary"].split("/").pop(),
  },
];

export const ProductMerchandiseSteps = [
  {
    label: "Type",
    value: routes.merchandise.type.split("/").pop(),
  },
  {
    label: "Info",
    value: routes.merchandise.info.split("/").pop(),
  },
  {
    label: "Variant",
    value: routes.merchandise.ticket.split("/").pop(),
  },
  {
    label: "Pricing & Extras",
    value: routes.merchandise.price.split("/").pop(),
  },
  {
    label: "Summary",
    value: routes.merchandise.summary.split("/").pop(),
  },
];

export const ProductMembershipSteps = [
  {
    label: "Type",
    value: routes.membership.type.split("/").pop(),
  },
  {
    label: "Info",
    value: routes.membership.info.split("/").pop(),
  },
  {
    label: "Expiry",
    value: routes.membership.expiry.split("/").pop(),
  },
  {
    label: "Discounts",
    value: routes.membership.discount.split("/").pop(),
  },
  {
    label: "Member only product",
    value: routes.membership["member-only-product"].split("/").pop(),
  },
  {
    label: "Restrictions",
    value: routes.membership.restrictions.split("/").pop(),
  },
  {
    label: "Pricing & Extras",
    value: routes.membership.pricing.split("/").pop(),
  },
  {
    label: "Summary",
    value: routes.membership.summary.split("/").pop(),
  },
]

export const InventoryType = [
  {
    label: "Dynamic - dependent on the available resource quantity",
    value: "dynamic",
  },
];

export const Deposit = [
  {
    label: "% of order total amount",
    value: "percentage",
  },
  {
    label: "Fixed amount per order",
    value: "fixed",
  },
];


export const DiscountAmountOptions = [
  {
    label: "Fixed",
    value: "fixed",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
];

export const ProductStatusOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];


export const GIFT_CARD_VALUES_AND_EXPIRY_OPTIONS = [
  {
    label: "Fixed dates",
    value: "fixed_date",
  },
  {
    label: "Fixed days after purchase",
    value: "fixed_days",
  },
  {
    label: "Never",
    value: "never_expires",
  },
];

export const MEMBERSHIP_EXPIRY_OPTIONS = [
  {
    label: "Fixed dates",
    value: "fixed_date",
  },
  {
    label: "Fixed days after purchase",
    value: "fixed_days",
  },
  {
    label: "Never",
    value: "never_expires",
  },
];

export const GIFT_CARD_VALUE_OPTION = [
  {
    label: "Fixed Amount",
    value: "fixed_amount",
  },
  {
    label: "Specific Product",
    value: "specific_products",
  },
];

export const RENTAL_PRODUCT_TICKETS_OPTIONS = [
  {
    label: "Multiday - In multi-day rental there is option to select days, weeks or month.",
    value: "multi_day",
  },
  {
    label:
      "Multiday - flexible time - In multi-day flex time user can select multi days with time.",
    value: "multi_day_flex",
  },
  {
    label:
      "Single day - flexible time - In single day we can select hour or minutes with flex time.",
    value: "single_day_flex",
  },
  {
    label: "Single day - fixed time - In single day fixed there is fixed start and end time.",
    value: "single_day_fixed",
  },
];

export const SHOW_MULTI_DAY_FLEX = false;

export const MULTI_DAY_FLEX_PRODUCT_TYPE = "Multi Day Flexible Time Rental";

export const CONDITIONAL_DISPLAY_RULES = [
  {
    label: "AND",
    value: "AND",
  },
  {
    label: "OR",
    value: "OR",
  },
];

export const FIELD_CONDITIONS = [
  {
    label: "Is",
    value: "is",
  },
  {
    label: "Is not",
    value: "is_not",
  },
];

export const CONDITIONAL_FIELD_TYPES = [
  {
    label: "Field",
    value: "field",
  },
  {
    label: "Ticket",
    value: "ticket",
  },
];

export const BookingLimitationOptions = [
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Day",
    value: "day",
  },
  {
    label: "Year",
    value: "year",
  }
]

export const CONFIRM_BOOKING_OPTIONS_PRODUCT_BASED = [
  {
    label: "Automatic",
    value: true,
    helperText: 'Orders placed by your customers will be automatically confirmed.'
  },
  {
    label: "Manually",
    value: false,
    helperText:'Customers orders will have a "Pending confirm" status. Reviewing and confirming each order must be done manually.'
  },
];
